import React, { Component } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { timestampToDate } from '../../../util/dates';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';

import {
  Form,
  PrimaryButton,
  NamedLink,
  FieldPhoneNumberInput,
  AvatarLarge
} from '../../../components';

import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';

export class BookingTimeFormFreeInquiryComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from this template's backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the orderData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;
    const {
      listingId,
      isOwnListing,
      currentUser,
      author,
    } = this.props;

    const customerId = currentUser?.id?.uuid;
    const providerId = author.id.uuid;

    // Note: we expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isStartBeforeEnd = bookingStartTime < bookingEndTime;

    if (
      bookingStartTime &&
      bookingEndTime &&
      isStartBeforeEnd &&
      !this.props.fetchLineItemsInProgress
    ) {
      this.props.onFetchTransactionLineItems({
        orderData: {
          bookingStart: startDate,
          bookingEnd: endDate,
          customerId,
          providerId,
        },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      dayCountAvailableForBooking,
      marketplaceName,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            fetchLineItemsInProgress,
            isProfilePanel,
            currentUser,
            location,
            invalid,
            offerDuration,
            onCloseInquiryModal,
            author
          } = fieldRenderProps;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;


          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={formState => {
                  const currentValues = formState.values;
                  const fieldToExclude = 'voucher';

                  if (values?.useVoucher?.length && !formState.values?.useVoucher?.length) {
                    form.change('voucher', undefined);
                  }

                  if (values[fieldToExclude] !== currentValues[fieldToExclude]) {
                    return;
                  }
                  this.handleOnChange(formState);
                }}
              />

              <AvatarLarge className={css.avatar} user={author} disableProfileLink />

              <h2 className={css.formTitle}>
                {intl.formatMessage({ id: 'BookingTimeForm.10minCallTitle' })}
              </h2>

              <p className={css.formDescription}>
                {intl.formatMessage({ id: 'BookingTimeForm.10minCallDescription' })}
              </p>

              <div className={css.phoneNumber}>
                <label htmlFor="" className={css.labelFreeInquiry}>
                  {intl.formatMessage({ id: 'BookingTimeForm.phoneNumber' })}
                </label>

                <FieldPhoneNumberInput
                  className={css.phoneNumberField}
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="+49 12345678901"
                  validate={
                    validators.composeValidators(
                      validators.required(
                        intl.formatMessage({
                          id: 'BookingTimeForm.phoneNumberRequired',
                        })
                      ),
                      validators.maxLength(intl.formatMessage(
                        { id: 'BookingTimeForm.maxLength' },
                        { maxLength: 20 }), 20),
                      validators.phoneFormatValid(intl.formatMessage(
                        { id: 'BookingTimeForm.invalidFormat' })
                      )
                    )
                  }
                />
              </div>

              <label htmlFor="" className={css.labelFreeInquiry}>
                {intl.formatMessage({ id: 'BookingTimeForm.bookingStartTitle' })}
              </label>
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  startDateInputProps={{
                    // label: intl.formatMessage({ id: 'BookingTimeForm.bookingStartTitle' }),
                    label: '',
                    placeholderText: startDatePlaceholder,
                  }}
                  endDateInputProps={{
                    // label: intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' }),
                    label: '',
                    placeholderText: endDatePlaceholder,
                  }}
                  className={classNames(css.bookingDates, css.bookingDatesFreeInquiry)}
                  listingId={listingId}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  dayCountAvailableForBooking={dayCountAvailableForBooking}
                  offerDuration={parseInt(offerDuration)}
                  isInquiryModal
                />
              ) : null}

              <div className={css.buttonWrapper}>

                <PrimaryButton
                  className={classNames(css.button, css.cancelButton)}
                  type="button"
                  onClick={() => onCloseInquiryModal()}
                >
                  <FormattedMessage id="BookingTimeForm.cancel" />
                </PrimaryButton>

                {!currentUser ? (
                  <NamedLink
                    name="LoginPage"
                    to={{
                      state: { from: `${location?.pathname}${location?.search}${location?.hash}` },
                    }}
                    className={classNames(css.button, css.submitButton)}
                  >
                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                  </NamedLink>
                ) : (
                  <PrimaryButton
                    className={classNames(css.button, css.submitButton)}
                    type="submit"
                    inProgress={fetchLineItemsInProgress}
                    disabled={invalid}
                  >
                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                  </PrimaryButton>
                )}
              </div>

            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormFreeInquiryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormFreeInquiryComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,

  dayCountAvailableForBooking: number.isRequired,
};

const BookingTimeFormFreeInquiry = compose(injectIntl)(BookingTimeFormFreeInquiryComponent);
BookingTimeFormFreeInquiry.displayName = 'BookingTimeFormFreeInquiry';

export default BookingTimeFormFreeInquiry;
