import React from 'react';
import classNames from 'classnames';

import {
  H4,
  NamedLink,
  OrderPanel,
  IconCategories,
  IconClientCategories,
  IconSessionDynamics,
  IconInsuranceCoverageInfo,
  IconInfoTip,
  IconMinus,
  H1,
} from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';

import { displayPrice } from '../../../util/configHelpers';

import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
// import SectionOffersMaybe from './SectionOffersMaybe';
// import CustomListingFields from '../CustomListingFields';

import css from './TreatmentListingContent.module.css';

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainer}>
      <div className={css.price} title={priceTitle}>
        {formattedPrice}
      </div>
      {/* <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div> */}
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      {/* <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div> */}
    </div>
  );
};

const TreatmentListingContent = props => {
  const {
    currentListing,
    profileListing,
    listingId,
    listingSlug,
    listingPathParamType,
    listingType,
    listingConfig,
    location,
    offerListings,
    fetchOffesrInProgress,
    fetchOffesrError,

    isOwnListing,
    authorDisplayName,
    ensuredAuthor,
    currentUser,
    isAuthenticated,

    intl,
    firstTx,
    config,
    params,
    redemptions,
    reviews,
    fetchReviewsError,
    sendInquiryError,
    fetchLineItemsError,
    rules,
    inquiryModalOpen,
    onContactUser,
    onSubmitInquiry,
    setInquiryModalOpen,
    handleOrderSubmit,
    sendInquiryInProgress,
    isFirstTransaction,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    voucherValid,
    listingTab,
    richTitle,
    voucherError,
    payoutDetailsWarning,
    noPayoutDetailsSetWithOwnListing,
    onValidateVoucher,
    onManageDisableScrolling,
    isStripeConnected
  } = props;

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
  } = currentListing.attributes;

  const listingFields = listingConfig?.listingFields;

  // const isOpenForCollaborations = publicData?.collaborationAgree;

  const holisticHealthFocus = publicData?.holisticHealthFocus;
  const holisticHealthFocusOptions = listingFields.find(f => f.key === 'holisticHealthFocus')
    ?.enumOptions;
  const holisticHealthFocusLabels = holisticHealthFocusOptions.filter(o =>
    holisticHealthFocus?.find(t => t === o.option)
  );

  const sessionDynamics = publicData?.sessionDynamics;
  const sessionDynamicsOptions = listingFields.find(f => f.key === 'sessionDynamics')?.enumOptions;
  const sessionDynamicsLabels = sessionDynamicsOptions.filter(o =>
    sessionDynamics?.find(t => t === o.option)
  );

  const clientCategories = publicData?.clientCategories;
  const clientCategoriesOptions = listingFields.find(f => f.key === 'clientCategories')
    ?.enumOptions;
  const clientCategoriesLabels = clientCategoriesOptions.filter(o =>
    clientCategories?.find(t => t === o.option)
  );

  const notSuitable = publicData?.notSuitable;

  const insuranceCoverage = publicData?.insuranceCoverage;
  const insuranceCoverageOptions = listingFields.find(f => f.key === 'insuranceCoverage')
    ?.enumOptions;
  const insuranceCoverageLabel = insuranceCoverageOptions.filter(
    o => o.option === insuranceCoverage
  );

  const showMap = publicData?.offerLocation === 'in-person';

  // const offerIntroduction = publicData?.offerIntroduction;
  const offerDuration = publicData?.offerDuration;

  const offerLocation = publicData?.offerLocation;
  const offerLocationOptions = listingFields?.find(f => f.key === 'offerLocation')?.enumOptions;
  const offerLocationLabel = offerLocationOptions?.find(o => offerLocation === o.option)?.label;

  return (
    <div className={css.contentWrapperForProductLayout}>
      <div className={css.sectionHeroWrapper}>
        <div className={css.sectionHeroContainer}>
          {currentListing.id && noPayoutDetailsSetWithOwnListing && (
            <ActionBarMaybe
              className={css.actionBarForProductLayout}
              isOwnListing={isOwnListing}
              listing={currentListing}
              showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
            />
          )}
          {currentListing.id && (
            <ActionBarMaybe
              className={css.actionBarForProductLayout}
              isOwnListing={isOwnListing}
              listing={currentListing}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingPathParamType,
                tab: listingTab,
                kind: listingType === 'profile-booking' ? 'profile' : 'treatment',
              }}
            />
          )}
          <div className={css.sectionHero}>
            <SectionGallery
              rootClassName={css.productGalleryHolder}
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />

            <div className={css.orderColumnForProductLayout}>
              <OrderPanel
                className={classNames(css.productOrderPanel, css.productOrderPanelMain)}
                listing={currentListing}
                currentUser={currentUser}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                authorLink={
                  <NamedLink
                    className={css.authorNameLink}
                    name="ListingPage"
                    params={params}
                    to={{ hash: '#author' }}
                  >
                    {authorDisplayName}
                  </NamedLink>
                }
                title={
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                }
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                }
                payoutDetailsWarning={payoutDetailsWarning}
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                validListingTypes={config.listing.listingTypes}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
                isTreatmentPanel
                listingFields={listingFields}
                onValidateVoucher={onValidateVoucher}
                voucherError={voucherError}
                voucherValid={voucherValid}
                isFirstTransaction={isFirstTransaction}
                rules={rules}
                redemptions={redemptions}
                firstTx={firstTx}
                isStripeConnected={isStripeConnected}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={css.mainColumnForProductLayout}>
        {/* <div className={css.mobileHeading}>
          <H4 as="h1" className={css.orderPanelTitle}>
          <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
          </H4>
        </div> */}

        <div className={css.headingHolder}>
          <div className={css.titleHolder}>
            <H1 className={css.heading}>{title}</H1>

            <PriceMaybe
              price={price}
              publicData={publicData}
              validListingTypes={config.listing.listingTypes}
              intl={intl}
              marketplaceCurrency={config.currency}
            />
          </div>

          <div className={css.headingInfoHolder}>
            {!!offerDuration && (
              <p className={css.offerDuration}>
                <span className={css.offerDurationLabel}>
                  <FormattedMessage id="ListingPage.duration" />
                </span>
                {offerDuration} Min
              </p>
            )}
            {!!offerLocation && (
              <div
                className={classNames(
                  css.offerLocation,
                  { [css.offerLocationInPerson]: offerLocation === 'in-person' },
                  { [css.offerLocationOnline]: offerLocation === 'online' },
                  { [css.offerLocationClientLocation]: offerLocation === 'client-location' }
                )}
              >
                {offerLocationLabel}
              </div>
            )}
          </div>
        </div>

        {!!holisticHealthFocusLabels?.length && (
          <ul className={css.holisticHealthFocusList}>
            {holisticHealthFocusLabels.map((t, i) => {
              return (
                <li key={t.option} className={css.holisticHealthFocusListItem}>
                  <IconCategories type={t.option} />
                  {t.label}
                </li>
              );
            })}
          </ul>
        )}

        <SectionTextMaybe
          text={description}
          heading={<FormattedMessage id="ListingPage.serviceDescriptionTitle" />}
          isFirst
        />

        <div className={css.sectionRow}>
          {!!sessionDynamicsLabels?.length && (
            <section className={css.section}>
              <h3 className={classNames(css.sectionHeading, css.sectionHeadingSmall)}>
                <FormattedMessage id="ListingPage.sessionDynamicsTitle" />
              </h3>
              <ul className={css.sectionItemsList}>
                {sessionDynamicsLabels.map((s, i) => {
                  return (
                    <li key={s.option} className={css.sectionItemsListItem}>
                      <IconSessionDynamics type={s.option} />
                      {s.label}
                    </li>
                  );
                })}
              </ul>
            </section>
          )}

          {!!clientCategoriesLabels?.length && (
            <section className={css.section}>
              <h3 className={classNames(css.sectionHeading, css.sectionHeadingSmall)}>
                <FormattedMessage id="ListingPage.bestSuitedForTitle" />
              </h3>
              <ul className={css.sectionItemsList}>
                {clientCategoriesLabels.map((c, i) => {
                  return (
                    <li key={c.option} className={css.sectionItemsListItem}>
                      <IconClientCategories type={c.option.trim()} />
                      {c.label}
                    </li>
                  );
                })}
              </ul>
            </section>
          )}

          {!!notSuitable && (
            <section className={css.section}>
              <h3 className={classNames(css.sectionHeading, css.sectionHeadingSmall)}>
                <FormattedMessage id="ListingPage.notSuitableFor" />
              </h3>
              <p className={css.sectionSubHeading}>
                <IconMinus />
                {notSuitable}
              </p>
            </section>
          )}

          {!!insuranceCoverageLabel && insuranceCoverage !== 'not-covered' && (
            <section className={css.section}>
              <h3 className={classNames(css.sectionHeading, css.sectionHeadingSmall)}>
                <FormattedMessage
                  id="ListingPage.insuranceCoverageTitle"
                  values={{ breakLine: <br /> }}
                />
              </h3>
              <ul className={css.sectionItemsList}>
                {insuranceCoverageLabel.map((c, i) => {
                  return (
                    <li key={c.option} className={css.sectionItemsListItem}>
                      <IconInsuranceCoverageInfo />
                      {c.label}
                    </li>
                  );
                })}
              </ul>

              <p className={css.sectionInfoTip}>
                <IconInfoTip />
                <FormattedMessage id="ListingPage.insuranceCoverageDescription" />
              </p>
            </section>
          )}
        </div>

        {showMap && (
          <SectionMapMaybe
            geolocation={geolocation}
            publicData={publicData}
            listingId={currentListing.id}
            mapsConfig={config.maps}
          />
        )}

        <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />

        <SectionAuthorMaybe
          title={title}
          listing={profileListing}
          authorDisplayName={authorDisplayName}
          onContactUser={onContactUser}
          isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
          onCloseInquiryModal={() => setInquiryModalOpen(false)}
          sendInquiryError={sendInquiryError}
          sendInquiryInProgress={sendInquiryInProgress}
          onSubmitInquiry={onSubmitInquiry}
          currentUser={currentUser}
          onManageDisableScrolling={onManageDisableScrolling}
          listingFields={listingFields}
          location={location}

          isOwnListing={isOwnListing}
          marketplaceCurrency={config.currency}
          dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          marketplaceName={config.marketplaceName}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
          payoutDetailsWarning={payoutDetailsWarning}
        />
      </div>
    </div>
  );
};

export default TreatmentListingContent;
