import React, { useEffect, useRef, useState } from 'react';
// import * as pdfjsLib from 'pdfjs-dist/webpack';
import axios from 'axios';
import css from './AzureImageDisplay.module.css';


const PdfToJpegConverter = ({ pdfUrl, value }) => {
  const canvasRef = useRef(null);
  const [jpegUrl, setJpegUrl] = useState(null);

  useEffect(() => {
    if(typeof window !== 'undefined') {
      const pdfjsLib = require('pdfjs-dist/webpack');
      const convertPdfToJpeg = async () => {
        const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';

        await axios.get(
          `${isDev ? 'http://localhost:3500' : ''}/api/azure-download?fileName=` + value,
          {
            responseType: 'blob',
          },
        ).then(async resp => {

          const reader = new FileReader();
          reader.readAsDataURL(resp.data);
          reader.onloadend = async function() {
            const base64data = reader.result;
            setJpegUrl(base64data);

            const encodedData = window.btoa(base64data); // encode a string
            const decodedData = window.atob(encodedData); // decode the string

            const pdf = await pdfjsLib.getDocument(decodedData).promise;
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            await page.render(renderContext).promise;

            const jpegDataUrl = canvas.toDataURL('image/jpeg');
            setJpegUrl(jpegDataUrl);
          };

        }).catch(error => {
          console.log(error);
        });
      };

      convertPdfToJpeg();
    }

  }, [pdfUrl]);

  return (
    <div className={css.imageHolder}>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      {jpegUrl && (
        <img src={jpegUrl} alt='Converted from PDF' />
      )}
    </div>
  );
};

export default PdfToJpegConverter;
