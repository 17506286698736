import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { INQUIRY_PROCESS_NAME, resolveLatestProcessName } from '../../../transactions/transaction';
import { Heading, Modal } from '../../../components';
import UserCard from '../UserCard/UserCard';
import InquiryForm from '../InquiryForm/InquiryForm';
import BookingTimeFormFreeInquiry from '../../../components/OrderPanel/BookingTimeForm/BookingTimeFormFreeInquiry';
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl';

import css from './ProfileListingContent.module.css';

const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();
const OFFER_DURATION = 10;

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isInquiryModalOpen,
    onCloseInquiryModal,
    sendInquiryError,
    sendInquiryInProgress,
    onSubmitInquiry,
    currentUser,
    onManageDisableScrolling,


    isOwnListing,
    lineItemUnitType: lineItemUnitTypeMaybe,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    monthlyTimeSlots,
    onFetchTimeSlots,
    marketplaceName,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,

    location,
    intl
  } = props;

  if (!listing.author) {
    return null;
  }

  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isInquiryProcess = processName === INQUIRY_PROCESS_NAME;

  const publicData = listing?.attributes?.publicData || {};
  const { unitType } = publicData || {};
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;

  return (
    <section id="author" className={css.sectionAuthor}>
      {/* <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.aboutProviderTitle" />
      </Heading> */}
      <UserCard
        title={title}
        listing={listing}
        user={listing.author}
        currentUser={currentUser}
        onContactUser={onContactUser}
        showContact
        onManageDisableScrolling={onManageDisableScrolling}
      />
      <Modal
        id="ListingPage.inquiry"
        containerClassName={css.inquiryModalContainer}
        contentClassName={css.inquiryModalContent}
        isOpen={isInquiryModalOpen}
        onClose={onCloseInquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >

        <BookingTimeFormFreeInquiry
          author={listing.author}
          className={css.bookingForm}
          formId="BookingTimeFormFreeInquiry"
          onSubmit={onSubmitInquiry}
          listingId={listing.id}
          currentUser={currentUser}
          isProfilePanel
          isOwnListing={isOwnListing}
          price={listing?.attributes?.price}
          lineItemUnitType={lineItemUnitType}
          marketplaceCurrency={marketplaceCurrency}
          dayCountAvailableForBooking={dayCountAvailableForBooking}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={timeZone}
          marketplaceName={marketplaceName}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
          payoutDetailsWarning={payoutDetailsWarning}

          location={location}
          offerDuration={OFFER_DURATION}
          isFreeInquiry
          onCloseInquiryModal={onCloseInquiryModal}
        />

        {/* <InquiryForm
          className={css.inquiryForm}
          submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendInquiryError={sendInquiryError}
          onSubmit={onSubmitInquiry}
          inProgress={sendInquiryInProgress}
        /> */}
      </Modal>
    </section>
  );
};

export default compose(
  withRouter,
  injectIntl
)(SectionAuthorMaybe);

